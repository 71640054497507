<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
      <div class="edit-header">
        <span>建设项目预审与选址意见书</span>
        <div>
          <el-button type="primary" @click="print">打印</el-button>
          <el-button type="primary" v-show="!isDisabled" @click="onSubmit"
          >保存</el-button
          >
        </div>
      </div>
      </FloatTitBtn>

      <div class="edit-container">
        <el-form
          ref="baseform"
          :label-position="labelPosition"
          :model="form"
          label-width="120px"
        >
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="文号:">
                 <el-input @blur="onSubmit('one')" v-model="form.WH" :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="项目代码:">
                 <el-input @blur="onSubmit('one')" v-model="form.XMDM" :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="项目名称:">
                 <el-input @blur="onSubmit('one')" v-model="form.XMMC" :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">

          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="建设单位名称:">
                 <el-input @blur="onSubmit('one')" v-model="form.JSDWMC" :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="项目建设依据:">
                 <el-input @blur="onSubmit('one')" v-model="form.XMJSYJ" :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="项目拟选位置:">
                 <el-input @blur="onSubmit('one')" v-model="form.XMNJWZ" :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="拟用地面积（含地类明细）:">
                 <el-input @blur="onSubmit('one')" 
                  :disabled="isDisabled"
                  v-model="form.NYDMJ"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="建设规模:">
                  <el-input @blur="onSubmit('one')"
                 :disabled="isDisabled"
                  v-model="form.NJSGM"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="附件附图名称:">
                 <el-input @blur="onSubmit('one')"
                  v-model="form.FJFUMC"
                  type="textarea"
                  :disabled="isDisabled"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12"> </el-col>
            <el-col :span="12">
              <el-form-item label="发证日期:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.FZRQ"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <div v-show="false">
      <div id="form2">
       <div style="font-family: 'SimSun';font-size: 17px;color: black;height:100%;position:relative;">
         <div style="width:49%;display:inline-block;height:100%;">
           <div style="margin-top: 280px;margin-left:75%;white-space: nowrap;">{{ form.WH }}&ensp;</div>
           <div style="margin-top: 350px;margin-left:74%;">慈溪市自然资源和规划局</div>
           <div style="margin-top: 25px;margin-left:75%;">{{ getDate(form.FZRQ) }}&ensp;</div>
         </div>
         <div style="display:inline-block;width:300px;position:absolute;top:8%;left:76%;">
           <div style="height: 50px;">
             {{ form.XMMC }}&ensp;
           </div>
           <div style="height: 50px;">
             {{ form.XMDM }}&ensp;
           </div>
           <div style="height: 50px;">
             {{ form.JSDWMC }}&ensp;
           </div>
           <div style="height: 50px;">
             {{ form.XMJSYJ }}&ensp;
           </div>
           <div style="height: 60px;">
             {{ form.XMNJWZ }}&ensp;
           </div>
           <div style="height: 75px;">
             {{ form.NYDMJ }}&ensp;
           </div>
           <div style="height: 55px;">
             {{ form.NJSGM }}&ensp;
           </div>
         </div>
         <div style="position:absolute;top:64%;left:67%;">
             <div v-for="(v, i) in picNameArray" :key="i" style="font-size: 17px;width:400px;">
                <span style="line-height:10px;">{{ v }}</span>
              </div>
           </div>  
       </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ElMessage } from "element-plus";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
import print from "print-js";
import dayjs from "dayjs";
export default {
  name:'xzyjs',
  components: { FloatTitBtn },
  data() {
    return {
      labelPosition: "left",
      form: {
        Id: '',
        BJID: '',
        WH: '',
        XMMC: '',
        XMDM: '',
        JSDWMC: '',
        XMJSYJ: '',
        XMNJWZ: '',
        NYDMJ: '',
        NJSGM: '',
        FJFUMC: '',
        FZRQ: new Date(),
      },
      sblxdisabled: false,
      savebuttonshow: true,
      isDisabled: true,
      picNameArray:['','']
    };
  },
  methods: {
    print() {
      print({
        printable: "form2",
        type: "html",
        scanStyles: false,
        css: "/static/landscapeA3.css",
        documentTitle: "",
      });
    },
    async onSubmit(type) {
      let me = this;
      if (me.checkform()) {
        await this.$ajax
          .post(me.$appConfig.apiUrls.ywxtApi + "/api/YW/AddorUpdatexzyjs", me.form)
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.form.Id = response.data.Data;
              if(me.form.FJFUMC!=null && me.form.FJFUMC!=undefined){
                me.picNameArray = me.form.FJFUMC.split('\n')
              }
              if(type!='one'){
              ElMessage({
                type: "success",
                message: "保存成功",
              });
              }
            } else {
              ElMessage({
                type: "info",
                message: "保存失败!",
              });
              console.log(response.data.errorMessage);
            }
          });
      }
    },
    getDate(date) {
      var a = date
      a= dayjs(date).format('YYYY年MM月DD日')
      return a
    },
    Getlahz() {
      let me = this;
      this.$ajax
        .get(me.$appConfig.apiUrls.ywxtApi + "/api/YW/Getlahz?Id=" + this.$route.query.instanceId)
        .then(function (response) {
          if (response.data.IsSuccess) {
            let res = response.data.Data;
            me.form.XMMC = res.XMMC;
            me.form.JSDWMC = res.JSDW;
            me.form.XMNJWZ = res.JSWZ;
          }
        });
    },
    //转化json
    switch() {
      
      let me = this;
      me.form.BJID = this.$route.query.instanceId;
      if (me.form.BJID) {
        this.$ajax
          .get(me.$appConfig.apiUrls.ywxtApi + "/api/YW/getxzyjs?BJID=" + me.form.BJID)
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.form = response.data.Data;
              if(!me.form.Id){
                me.Getlahz();
              }
              if(me.form.FJFUMC!=null && me.form.FJFUMC!=undefined){
                me.picNameArray = me.form.FJFUMC.split('\n')
              }
              // me.form.FZRQ=new Date()
            } else {
              ElMessage({
                type: "info",
                message: "初始化表单失败",
              });
            }
            if (me.$route.query.taskId) {
              me.isDisabled = false;
            }
          });
      }

    },
    checkform() {
      let b = true;
      return b;
    },
    checkinput(val, mess) {
      if (!val || val.length < 1) {
        ElMessage({
          type: "info",
          message: "请输入" + mess,
        });
        return false;
      } else {
        return true;
      }
    },

  },
  mounted() {
    this.switch();
  },
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
.wrap-edit .edit-header {
  margin-bottom: 15px;
}
</style>
